<template>
  <div class="col-wrapper">
    <svg
      width="9"
      height="22"
      viewBox="0 0 9 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 21.3125V0.6875C3 0.309375 2.6625 0 2.25 0H0.75C0.3375 0 0 0.309375 0 0.6875V21.3125C0 21.6906 0.3375 22 0.75 22H2.25C2.6625 22 3 21.6906 3 21.3125ZM9 21.3125V0.6875C9 0.309375 8.6625 0 8.25 0H6.75C6.3375 0 6 0.309375 6 0.6875V21.3125C6 21.6906 6.3375 22 6.75 22H8.25C8.6625 22 9 21.6906 9 21.3125Z"
        :fill="color"
      />
    </svg>
    <svg
      width="9"
      height="22"
      viewBox="0 0 9 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 21.3125V0.6875C3 0.309375 2.6625 0 2.25 0H0.75C0.3375 0 0 0.309375 0 0.6875V21.3125C0 21.6906 0.3375 22 0.75 22H2.25C2.6625 22 3 21.6906 3 21.3125ZM9 21.3125V0.6875C9 0.309375 8.6625 0 8.25 0H6.75C6.3375 0 6 0.309375 6 0.6875V21.3125C6 21.6906 6.3375 22 6.75 22H8.25C8.6625 22 9 21.6906 9 21.3125Z"
        :fill="color"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#000"
    }
  }
};
</script>

<style lang="scss" scoped>
.col-wrapper {
  display: flex;
  svg:first-child {
    margin-right: 3px;
  }
}
</style>
